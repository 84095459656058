import DemoAuthorImageProfWoman from "../assets/images/avatars/professional-woman.png";
import DemoAuthorImageYoungWoman1 from "../assets/images/avatars/young-woman-flower-in-hair.png";
import DemoAuthorImage from "../assets/images/logos/mcl-favicon-144.png";
import HelpingGrandma from "../assets/images/why/helping-grandma.jpg";
import MCLAffordable from "../assets/images/why/MCL-is-Affordable.jpg";
import MCLSupport from "../assets/images/why/MCL-support.jpg";
import MCLChecklist from "../assets/images/library/checklist.jpg";
import MCLCaring from "../assets/images/library/caring.jpg";
import PlaceHolderFeatureImage from "../assets/images/team/banner_bubbles_bg.png";
// import PlaceHolderAuthorImage from "../assets/images/avatars/professional-woman.png";
import PlaceHolderAuthorImage from "../assets/images/logos/mcl-favicon-144.png";
import {
  RiUserFill,
  RiGroupFill,
  RiCustomerService2Fill,
  RiArrowGoBackFill,
  RiCoinsFill,
} from "react-icons/ri";
import {
  FaConciergeBell,
  FaBriefcaseMedical,
  FaUserAlt,
  FaHome,
  FaUsers,
  FaChurch,
  FaHandshake,
} from "react-icons/fa";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { GoLaw } from "react-icons/go";
import _, { kebabCase } from "lodash";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

export const Care = {
  Reason: {
    INCARE: 1,
    SELFCARE: 2,
    PROVIDER: 3,
    INVITEDONLY: 4,
  },
  Care: {
    INCARE: 1,
    SELFCARE: 2,
    INVITEDONLY: 4,
  },
  Relation: {
    MOM: 1,
    DAD: 2,
    SISTER: 3,
    BROTHER: 4,
    AUNT: 5,
    UNCLE: 6,
    DAUGHTER: 7,
    SON: 8,
    FRIEND: 9,
    OTHER: 10,
    WIFE: 11,
    HUSBAND: 12,
    PARTNER: 13,
  },
};

export const RelationToStr = (code) => {
  switch (code) {
    case Care.Relation.MOM:
      return "Mom";
    case Care.Relation.DAD:
      return "Dad";
    case Care.Relation.SISTER:
      return "Sister";
    case Care.Relation.BROTHER:
      return "Brother";
    case Care.Relation.AUNT:
      return "Aunt";
    case Care.Relation.UNCLE:
      return "Uncle";
    case Care.Relation.DAUGHTER:
      return "Daughter";
    case Care.Relation.SON:
      return "Son";
    case Care.Relation.FRIEND:
      return "Friend";
    case Care.Relation.OTHER:
      return "Other";
    case Care.Relation.WIFE:
      return "Wife";
    case Care.Relation.HUSBAND:
      return "Husband";
    case Care.Relation.PARTNER:
      return "Partner";
    default:
      return "Error";
  }
};

export const CareType = {
  INCARE: 1,
  SELFCARE: 2,
  INVITEDONLY: 4,
};
export const CareTypeToStr = (type) => {
  // console.log("***" + CareTypeToStr.name + " CareType=" + type);
  switch (type) {
    case CareType.INCARE:
      return "A CARE PLAN have been created for:";
    case CareType.SELFCARE:
      return "A SELF CARE plan for";
    case CareType.INVITEDONLY:
      return "INVITED MEMBER to care team for:";
    default:
      return "Error";
  }
};
export const CareTypeToColor = (type) => {
  // console.log("***" + CareTypeToColor.name + " CareType=" + type);
  switch (type) {
    case CareType.INCARE:
      return "rgba(129, 89, 245, 0.8)";
    case CareType.SELFCARE:
      return "rgba(33, 118, 113, 0.8)";
    case CareType.INVITEDONLY:
      return "rgba(224, 85, 27, 0.8)";
    default:
      return "rgba(255, 0, 0, 0.8)";
  }
};
export const CareTypeToColorHover = (type) => {
  // console.log("CareType:", type);
  switch (type) {
    case CareType.INCARE:
      return "rgba(129, 89, 245, 0.65)";
    case CareType.SELFCARE:
      return "rgba(33, 118, 113, 0.65)";
    case CareType.INVITEDONLY:
      return "rgba(224, 85, 27, 0.65)";
    default:
      return "rgba(255, 0, 0, 0.65)";
  }
};
export const CareTypeToColorFocus = (type) => {
  // console.log("CareType:", type);
  switch (type) {
    case CareType.INCARE:
      return "rgba(129, 89, 245, 0.5)";
    case CareType.SELFCARE:
      return "rgba(33, 118, 113, 0.5)";
    case CareType.INVITEDONLY:
      return "rgba(16, 8, 45, 0.5)";
    default:
      return "rgba(255, 0, 0, 0.5)";
  }
};
export const CareLevel = {
  PRIMARY_ADMIN: 1,
  BACKUP_ADMIN: 2,
  INVITED_MEMBER: 4,
  PENDING_MEMBER: 5,
};
export const CareLevelToColor = (level) => {
  // console.log("*#*#*CareLevelToColor level:", level);
  switch (level) {
    case CareLevel.PRIMARY_ADMIN:
      return "rgba(129, 89, 245, 0.8)";
    // return "rgba(244, 85, 27, 1.0)";
    case CareLevel.BACKUP_ADMIN:
      return "rgba(33, 118, 113, 0.8)";
    // return "rgba(244, 85, 27, 0.8)";
    case CareLevel.INVITED_MEMBER:
      return "rgba(60, 48, 102, 0.8)";
    // return "rgba(129, 89, 245, 1.0)";
    case CareLevel.PENDING_MEMBER:
      return "rgba(129, 89, 245, 0.5)";
    default:
      // return "rgba(255, 0, 0, 0.5)";
      return "rgba(223, 31, 30, 0.8)";
  }
};

export const IsAdmin = (userId, circle) => {
  if (_.isEmpty(circle || !circle.primary)) {
    return false;
  }
  for (let i = 0; i < circle.primary.length; i++) {
    if (circle.primary[i]._id === userId) {
      return true;
    }
  }
  return false;
};
export const IsBackupAdmin = (userId, circle) => {
  if (
    !_.isEmpty(circle) &&
    circle.secondary &&
    circle.secondary._id === userId
  ) {
    return true;
  }
  return false;
};
export const IsMember = (userId, circle) => {
  // console.log("~~~~~~IsMember userId:", userId);
  // console.log("~~~~~~~~~~~~~~ circle:\n", circle);
  if (_.isEmpty(circle) || !circle.members) {
    // console.log("~~~~~~IsMember returned false");
    return false;
  }
  for (let i = 0; i < circle.members.length; i++) {
    // console.log(
    //   "~~~~~~IsMember i=" + i + " circle.members[i]=" + circle.members[i]._id
    // );
    if (circle.members[i]._id === userId) {
      return true;
    }
  }
  // console.log("~~~~~~IsMember returned false");
  return false;
};
export const IsPendingMember = (userId, circle) => {
  if (_.isEmpty(circle) || !circle.pendings) {
    return false;
  }
  for (let i = 0; i < circle.pendings.length; i++) {
    if (circle.pendings[i]._id === userId) {
      return true;
    }
  }
  return false;
};
export const PlanRoleToStr = (userId, circle) => {
  // console.log(">>>>>>PlanRoleToStr userId:\n", userId);
  // console.log(">>>>>>PlanRoleToStr circle:\n", circle);
  if (IsAdmin(userId, circle)) {
    return "PLAN ADMIN";
  }
  if (IsBackupAdmin(userId, circle)) {
    return "BACKUP ADMIN";
  }
  if (IsMember(userId, circle)) {
    return "INVITED MEMBER";
  }
  return "UNKNOWN";
};
export const PlanRoleToColor = (userId, circle) => {
  if (IsAdmin(userId, circle)) {
    return "rgba(129, 89, 245, 0.8)";
    // return "rgba(244, 85, 27, 1.0)";
  }
  if (IsBackupAdmin(userId, circle)) {
    // return "rgba(33, 118, 113, 0.8)";
    return "rgba(33, 118, 113, 0.8)";
  }
  for (let i = 0; i < circle.members.length; i++) {
    if (circle.members[i]._id === userId) {
      // return "rgba(129, 89, 245, 0.8)";
      return "rgba(60, 48, 102, 0.8)";
    }
  }
  return "rgba(223, 31, 30, 0.8)";
};

export const Subscription = {
  FREE: 0,
  PREMIUM: 1,
  SILVER: 2,
  STARTER: 3,
};
export const SubscriptionToStr = (code) => {
  // console.log("Subscription code:", code);
  switch (code) {
    case Subscription.FREE:
      return "Free";
    case Subscription.SILVER:
      return "Silver";
    case Subscription.PREMIUM:
      return "Premium";
    case Subscription.STARTER:
      return "Starter";
    default:
      // debugger;
      return "Default";
  }
};
export const SubscriptionToColor = (code) => {
  switch (code) {
    case Subscription.FREE:
      return "rgba(0, 255, 0, 0.5)";
    case Subscription.PREMIUM:
      return "rgba(255, 0, 255, 0.5)";
    case Subscription.SILVER:
      return "rgba(0, 0, 255, 0.5)";
    case Subscription.STARTER:
      return "rgba(0, 255, 255, 0.5)";
    default:
      return "rgba(255, 0, 0, 0.5)";
  }
};

export const Roles = {
  CAREGIVER: 121,
  CONCIERGE: 375,
  SUPER_CONCIERGE: 876,
  SUPER_ADMIN: 953,
};

export const RoleToStr = (role) => {
  switch (role) {
    case Roles.CAREGIVER:
      return "Caregiver";
    case Roles.CONCIERGE:
      return "Concierge";
    case Roles.SUPER_CONCIERGE:
      return "Super Concierge";
    case Roles.SUPER_ADMIN:
      return "Super Admin";
    default:
      return "Error";
  }
};

export const RoleToRoute = (role) => {
  switch (role) {
    case Roles.CAREGIVER:
      return "/caregiver/dashboard";
    case Roles.CONCIERGE:
      return "/concierge/dashboard";
    case Roles.SUPER_CONCIERGE:
      return "/supercierge/dashboard";
    case Roles.SUPER_ADMIN:
      return "/super/dashboard";
    default:
      return "/";
  }
};

export const DispositionCode = {
  0: "Pending - Lead received, pending contact",
  100: "Active - Free consultation appointment set",
  101: "Active - Free consultation completed - need to nurture ",
  102: "Active - Free consultation completed - pending upgrade to Silver",
  103: "Active - Client receiving Care Concierge services",
  104: "Active - Client requests another Care Concierge",
  105: "Active - Case escalation to supervisor ",
  200: "Inactive - Customer not responding to calls or emails",
  300: "Case closed - Unable to contact",
  301: "Case closed - Unable to locate",
  302: "Case closed - No showed appointments (2 or more in a 30 day period) ",
  303: "Case closed - Client stabilized",
  304: "Case closed - Declined services ",
  305: "Case closed - Free consultation completed, declined services",
  306: "Case closed - Other",
};

export const SurveyQuestions = {
  q1: "Medical coordination support for the person I’m caring for",
  q2: "Find personal care solutions and community support for the person I’m caring for",
  q3: "Assistance with Legal, Financial and Benefit management",
  q4: "Assistance with End-of Life care planning  and After-Life plans for the person in care",
};

export const WorkflowItems = [
  "1. Welcome and introductions",
  "2. Confirm name and contact information",
  "3.  Provide overview of consultation process.   Collect  background, review needs, goals, prioritize, and how CC can help.",
  "4. Discuss next steps, including scope of work, upgrading service for on-going service, order form.",
  "5.  Offer to schedule follow up appointment",
];
export const NextStepsItems = [
  "1. Develop and document collaboration goals, scope of work, and  action items ",
  "2. Send follow-up communication within 24 hours of consultation thanking them and outlining next steps.",
  "3. Ensure  consultation notes are accurate and complete.",
  "4. Disposition account status.",
  "5. For Premium members, schedule up to three follow up attempts within 30 days to encourage upgrading to Silver.",
  "6. If no response after 30 days, disposition account.",
  "7. When client upgrades, you will get a notification of the account status change.",
  "8. Set periodic reminders on your calendar (30, 60, 90 day time periods) to check-in.",
];

export const Url = {
  //    BASE: 'http://localhost:8080/api',
  BASE: process.env.REACT_APP_MCL_API,
  SUBSCRIBE: "/caregivers",
  REFERRAL: "/referrals",
  LOGIN: "/auth/login",
  CAREGIVERS: "/caregivers",
  CIRCLES: "/circle",
  PAYMENT: "/payment",
  GIFTING: "/gifting",
  CONCIERGES: "/concierge",
  FORMS: "/forms",
  SUPER_CONCIERGES: "/superConcierges",
  KNOWLEDGE_BASE: "/kb",
  REPORT: "/report",
  ARTICLE_CATEGORIES: "/articleCategories",
  ARTICLES: "/articles",
  DOWNLOAD: "/download",
  STATIC_IMAGES: process.env.REACT_APP_MCL_STATIC + "/images",
  VIDEOS: "/videos",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword",
  MESSAGES: "/messages",
  NOTIFICATIONS: "/notifications",
  INVITATION: "/invitation",
  B2B: "/b2b",
  PLAN: "/plan",
  CONFIRM: "/confirm",
};

export const SAVE_TIME_PERIOD = 1500;

export const categoryToPage = (category) => {
  const re = /^\w+(\d+)$/;
  const [_, page] = re.exec(category);
  // console.log('page:', page)
  return page;
};

export const FormDestination = {
  PRODUCT: "product",
  PRIVACY: "privacy",
  HELP: "help",
  QUESTIONS: "questions",
  BILLING: "billing",
  INFO: "info",
};

export const DATE_FORMAT = "MM-DD-YYYY";
export const DATE_RANGE_SEP = ":";

export const KB_CATEGORY = {
  Strings: {
    ARTICLE: "Article",
    ASSESSMENT: "Assessment",
    RESOURCE: "Resource",
    ECOURSE: "eCourse",
    REPORT: "Report",
    VENDOR: "Vendor",
    DATABASE: "Database",
    INSURANCE: "Insurance",
    PORTAL: "Portal",
    IN_HOME_CARE: "In-home Care",
    CAREGIVER_SUPPORT: "Caregiver Support",
    OTHER: "Other",
    RESEARCH: "Research Article",
  },
  Values: [
    "ARTICLE",
    "ASSESSMENT",
    "RESOURCE",
    "ECOURSE",
    "REPORT",
    "VENDOR",
    "DATABASE",
    "INSURANCE",
    "PORTAL",
    "IN_HOME_CARE",
    "CAREGIVER_SUPPORT",
    "OTHER",
    "RESEARCH",
  ],
};

export const KB_SPECIALTY = {
  Strings: {
    CAREGIVER_SUPPORT: "Caregiver Support",
    CAREGIVING: "Caregiving",
    CAREGIVING_COSTS: "Caregiving Costs",
    CARE_PLANNING: "Care Planning",
    CARE_CONSULTATION: "Care Consultation",
    IDENTIFYING_NEEDS: "Identifying Needs",
    AGING_LIFE_CARE: "Aging Life Care",
    MEMORY_CARE: "Memory Care",
    LIFE_CARE_PLANNING: "Life Care Planning",
    INSURANCE_APPEALS: "Insurance Appeals",
    SPICE_ACTIVITY_PROGRAM: "Spice Activity Program",
    DISABLED_ADULT_CARE: "Disabled Adult Care",
    SENIOR_LIVING: "Senior Living",
    SENIOR_CARE: "Senior Care",
    SELF_CARE: "Self Care",
    CULTURAL_COMPETENCE: "Cultural Competence",
    SUPPORT_GROUP: "Support Group",
    BUSINESS_INTELLIGENCE: "Business Intelligence",
    DEATH_DOULA: "Death Doula",
    END_OF_LIFE: "End-of-life",
    HOSPICE: "Hospice",
    AFTER_LIFE: "After-life",
    BURIALS_N_FUNERALS: "Burials & Funerals",
    DEMENTIA_PROGRAM_FOR_CAREGIVERS: "Dementia Program For Caregivers",
    IN_HOME_CARE: "In-home Care",
    HOME_CARE: "Home Care",
    BURDEN_SURVEY: "Burden Survey",
    COGNITIVE_TESTING: "Cognitive Testing",
    MEDICARE: "Medicare",
    CARE_ASSESSMENT: "Care Assessment",
    TRANSPORTATION: "Transportation",
    NAILS: "Nails",
    DURABLE_MEDICAL_EQUIPMENT: "Durable Medical Equipment",
    LGBT_SUPPORT: "Lgbt Support",
    CARE_MANAGEMENT: "Care Management",
    WALKER: "Walker",
    LONG_TERM_CARE: "Long-term Care",
    LONG_TERM_CARE_INSURANCE: "Long-term Care Insurance",
    MILITARY_SERVICE_MEMBER_BENEFITS: "Military Service Member Benefits",
    VETERANS_AFFAIRS: "Veterans Affairs",
    LABOR_PROFILE: "Labor Profile",
    LABOR_REPORT: "Labor Report",
    FINANCIAL_ASSISTANCE: "Financial Assistance",
    INSURANCE: "Insurance",
    MEDICAID: "Medicaid",
    CAREGIVER_RESOURCE: "Caregiver Resource",
    MASSAGE: "Massage",
    QUALITY_OF_LIFE: "Quality of Life",
    LEGACY: "Legacy",
    FRAUD_N_ABUSE: "Fraud and Abuse",
    MAIL: "Mail",
    POWER_OF_ATTORNEY: "Power of Attorney",
    ELDER_LAW: "Elder law",
    GERIATRIC_CARE_MANAGERS: "Geriatric Care Managers",
  },
  Values: [
    "CAREGIVER_SUPPORT",
    "CAREGIVING",
    "CAREGIVING_COSTS",
    "CARE_PLANNING",
    "CARE_CONSULTATION",
    "IDENTIFYING_NEEDS",
    "AGING_LIFE_CARE",
    "MEMORY_CARE",
    "LIFE_CARE_PLANNING",
    "INSURANCE_APPEALS",
    "SPICE_ACTIVITY_PROGRAM",
    "DISABLED_ADULT_CARE",
    "SENIOR_LIVING",
    "SENIOR_CARE",
    "SELF_CARE",
    "CULTURAL_COMPETENCE",
    "SUPPORT_GROUP",
    "BUSINESS_INTELLIGENCE",
    "DEATH_DOULA",
    "END_OF_LIFE",
    "HOSPICE",
    "AFTER_LIFE",
    "BURIALS_N_FUNERALS",
    "DEMENTIA_PROGRAM_FOR_CAREGIVERS",
    "IN_HOME_CARE",
    "HOME_CARE",
    "BURDEN_SURVEY",
    "COGNITIVE_TESTING",
    "MEDICARE",
    "CARE_ASSESSMENT",
    "TRANSPORTATION",
    "NAILS",
    "DURABLE_MEDICAL_EQUIPMENT",
    "LGBT_SUPPORT",
    "CARE_MANAGEMENT",
    "WALKER",
    "LONG_TERM_CARE",
    "LONG_TERM_CARE_INSURANCE",
    "MILITARY_SERVICE_MEMBER_BENEFITS",
    "VETERANS_AFFAIRS",
    "LABOR_PROFILE",
    "LABOR_REPORT",
    "FINANCIAL_ASSISTANCE",
    "INSURANCE",
    "MEDICAID",
    "CAREGIVER_RESOURCE",
    "MASSAGE",
    "QUALITY_OF_LIFE",
    "LEGACY",
    "FRAUD_N_ABUSE",
    "MAIL",
    "POWER_OF_ATTORNEY",
    "ELDER_LAW",
    "GERIATRIC_CARE_MANAGERS",
  ],
};

export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
export const UsState = {
  AL: { name: "Alabama", zipMin: 35004, zipMax: 36925 },
  AK: { name: "Alaska", zipMin: 99501, zipMax: 99950 },
  AZ: { name: "Arizona", zipMin: 85001, zipMax: 86556 },
  AR: { name: "Arkansas", zipMin: 71601, zipMax: 72959 },
  AS: { name: "American Samoa", zipMin: 96799, zipMax: 96799 },
  CA: { name: "California", zipMin: 90001, zipMax: 96162 },
  CO: { name: "Colorado", zipMin: 80001, zipMax: 86556 },
  CT: { name: "Connecticut", zipMin: 6001, zipMax: 6928 },
  DE: { name: "Delaware", zipMin: 19701, zipMax: 19980 },
  DC: { name: "District of Columbia", zipMin: 20001, zipMax: 20799 },
  FL: { name: "Florida", zipMin: 32004, zipMax: 34997 },
  GA: { name: "Georgia", zipMin: 30001, zipMax: 31999 },
  GU: { name: "Guam", zipMin: 96910, zipMax: 96932 },
  HI: { name: "Hawaii", zipMin: 96701, zipMax: 96898 },
  ID: { name: "Idaho", zipMin: 83201, zipMax: 83876 },
  IL: { name: "Illinois", zipMin: 60001, zipMax: 62999 },
  IN: { name: "Indiana", zipMin: 46001, zipMax: 47997 },
  IA: { name: "Iowa", zipMin: 50001, zipMax: 68120 },
  KS: { name: "Kansas", zipMin: 66002, zipMax: 67954 },
  KY: { name: "Kentucky", zipMin: 40003, zipMax: 42788 },
  LA: { name: "Louisiana", zipMin: 70001, zipMax: 71497 },
  ME: { name: "Maine", zipMin: 3901, zipMax: 4992 },
  MD: { name: "Maryland", zipMin: 20331, zipMax: 21930 },
  MA: { name: "Massachusetts", zipMax: 1001, zipMax: 5544 },
  MI: { name: "Michigan", zipMin: 48001, zipMax: 49971 },
  MN: { name: "Minnesota", zipMin: 55001, zipMax: 56763 },
  MS: { name: "Mississippi", zipMin: 38601, zipMax: 39776 }, // Mississippi (Warren) 71233
  MO: { name: "Missouri", zipMin: 63001, zipMax: 65899 },
  MT: { name: "Montana", zipMin: 59001, zipMax: 59937 },
  NE: { name: "Nebraska", zipMin: 68001, zipMax: 69367 },
  NV: { name: "Nevada", zipMin: 88901, zipMax: 89883 },
  NH: { name: "New Hampshire", zipMin: 3031, zipMax: 3897 },
  NJ: { name: "New Jersey", zipMin: 7001, zipMax: 8989 },
  NM: { name: "New Mexico", zipMin: 87001, zipMax: 88441 },
  NY: { name: "New York", zipMin: 10001, zipMax: 14975 },
  NC: { name: "North Carolina", zipMin: 27006, zipMax: 28909 },
  ND: { name: "North Dakota", zipMin: 58001, zipMax: 58856 },
  MP: { name: "Northern Mariana Islands", zipMin: 96950, zipMax: 96952 },
  OH: { name: "Ohio", zipMin: 43001, zipMax: 45999 },
  OK: { name: "Oklahoma", zipMin: 73001, zipMax: 74966 },
  OR: { name: "Oregon", zipMin: 97001, zipMin: 97920 },
  PA: { name: "Pennsylvania", zipMin: 15001, zipMax: 19640 },
  PR: { name: "Puerto Rico", zipMin: 600, zipMax: 999 },
  RI: { name: "Rhode Island", zipMin: 2801, zipMax: 2940 },
  SC: { name: "South Carolina", zipMin: 29001, zipMax: 29948 },
  SD: { name: "South Dakota", zipMin: 57001, zipMax: 57799 },
  TN: { name: "Tennessee", zipMin: 37010, zipMax: 38589 },
  TX: { name: "Texas", zipMin: 73301, zipMax: 88589 },
  TT: { name: "Trust Territories", zipMin: 582, zipMax: 582 },
  UT: { name: "Utah", zipMin: 84001, zipMax: 84784 },
  VT: { name: "Vermont", zipMin: 5001, zipMax: 5907 },
  VA: { name: "Virginia", zipMin: 20040, zipMax: 24658 },
  VI: { name: "Virgin Island", zipMin: 800, zipMax: 899 },
  WA: { name: "Washington", zipMin: 98001, zipMax: 99403 },
  WV: { name: "West Virginia", zipMin: 24701, zipMax: 26886 },
  WI: { name: "Wisconsin", zipMin: 53001, zipMax: 54990 },
  WY: { name: "Wyoming", zipMin: 82001, zipMax: 83128 },
};

export const LIBRARY_CATEGORIES = [
  {
    pathname: "getting-started",
    name: "Getting started",
  },
  {
    pathname: "financial",
    name: "Financial",
  },
  {
    pathname: "legal",
    name: "Legal",
  },
  {
    pathname: "in-home-care",
    name: "In-home care",
  },
  {
    pathname: "caregiver-resilience",
    name: "Caregiver resilience",
  },
  {
    pathname: "end-of-life-care",
    name: "End-of-life care",
  },
  {
    pathname: "after-life",
    name: "After-life",
  },
  {
    pathname: "etc",
    name: "Etc.",
  },
];

export const ARTICLE_CATEGORY_ROUTE = "/library/article/";
export const ARTICLE_ROUTE = "/article/";
export const VIDEO_CATEGORY_ROUTE = "/library/video/";
export const VIDEO_ROUTE = "/video/";

export const DEMO_ARTICLES = [
  {
    version: 1,
    featured: true,
    type: "blog",
    postStatus: "posted",
    title: "WHY MY CARELINQ?",
    excerpt:
      "Did you know Americans spent over $4 trillion for healthcare in 2020? Or that, even with insurance, a one-night-stay in the hospital averages around $11,700?",
    featuredImage: MCLAffordable,
    author: {
      name: "MyCareLinq",
      bio: "",
      photo: DemoAuthorImage,
    },
    url: "why-my-carelinq",
    createdAt: "Oct 08, 2021",
    categories: [
      {
        name: "getting-started",
      },
    ],
    tags: [],
    content: `_WE'RE AFFORDABLE_

###### My CareLinq helps alleviate the burden of managing a loved one’s care needs by partnering with healthcare institutions and providing additional transitional support and on-going case management.
                
&nbsp;&nbsp;
    
Did you know Americans spent over $4 trillion for healthcare in 2020? Or that, even with insurance, a one-night-stay in the hospital averages around $11,700? As more people get sick, age, or have a terminal diagnosis, our hospitals and providers are becoming overwhelmed dealing with non-medical and social needs. As the cost of care increases, insurance providers will continue to shift the cost to consumers. The ones left behind – the caregivers and survivors – are impacted the most. Research shows that not only can caregivers improve care recipients’ health, but they can also help reduce health care utilization and expenditure.
`,
  },
  {
    version: 1,
    featured: true,
    type: "blog",
    postStatus: "posted",
    title: "WHY NOW?",
    excerpt:
      "Today, there are 53+ million unpaid caregivers in the United States. More caregivers will be needed in the future as 71 million baby boomers will be over 65 years and older by 2030.",
    featuredImage: HelpingGrandma,
    author: {
      name: "MyCareLinq",
      bio: "",
      photo: DemoAuthorImage,
    },
    url: "why-now",
    createdAt: "Oct 08, 2021",
    categories: [
      {
        name: "getting-started",
      },
    ],
    tags: [],
    downloadOnly: true,
    downloadUrl: "",
    content: `_CAREGIVING HAS SIGNIFICANT PEOPLE IMPACT_

###### **Today, there are 53+ million unpaid caregivers in the United States. More caregivers will be needed in the future as 71 million baby boomers will be over 65 years and older by 2030. We not only care for older adults but we also care people who are recovering from an injury, surgery, managing a disability, or facing a chronic condition diagnosis.**
                
&nbsp;&nbsp;
    
Already, caregiving is reorganizing relations across American households and communities as these responsibilities extend into newer and younger generations. We find adults caring for children and parents, grandchildren caring for grandparents and vice versa, multiple people caring for an adult, and neighbors and friends caring for each other. The demands on caregivers are intense as they struggle to manage competing demands on their time all the while striving to care for their loved ones. As such, the needs of caregivers are complex, diverse, and dynamic.
                
For this reason, we need to innovate new ways to care… for our loved ones, for those giving care, and for each other. This kind of care means giving caregivers the technical and technological tools they need to carry out their everyday responsibilities and feel confident in doing so. It means personalizing solutions that are tailored and adapted to each caregivers’ circumstances, recognizing that every person’s needs (including that of the care recipient) are unique. It means expanding caregivers’ social networks and support systems so they do not feel alone in their journey but rather, belong to a wider community of peers to whom they can turn to for guidance. It means, most importantly, understanding caregiving as a deeply meaningful experience for caregivers and the ones for whom they care. The goal, therefore, is to seek out ways to enhance that caregiving journey.
`,
  },
  {
    version: 1,
    featured: true,
    type: "blog",
    postStatus: "posted",
    title: "HOW DOES MY CARELINQ HELP?",
    excerpt:
      "My CareLinq offers non-medical case management to caregivers and self-managing adults. Our services cover a large portion of supportive care across the care continuum.",
    featuredImage: MCLSupport,
    author: {
      name: "MyCareLinq",
      bio: "",
      photo: DemoAuthorImage,
    },
    url: "how-does-my-carelinq-help",
    createdAt: "Oct 08, 2021",
    categories: [
      {
        name: "getting-started",
      },
    ],
    tags: [],
    content: `_OFFERS NON-MEDICAL CASE MANAGEMENT_

###### **My CareLinq offers non-medical case management to caregivers and self-managing adults. Our services cover a large portion of supportive care across the care continuum. My CareLinq supports caregivers and patients through all stages of life regardless of age, location, and disease.**
                
&nbsp;&nbsp;
    
Did you know that medical care is estimated to account for only 10-20% of modifiable contributors to healthy outcomes and 80-90% is related to behaviors, socioeconomic and environmental factors?
                
A large portion of caregivers spend their time cajoling, organizing, coordinating, and vetting resources outside of institutional medical care. Food, shelter, social engagement, transportation, life transitions, tracking of documents, personal shopping and providing personal care are common caregiver responsibilities.
                
My CareLinq offers tools and expert resources to help ease the workload so that family caregivers can focus on what matters most. Families and communities can chip in, and all participate and support the care being provided.
`,
  },
  {
    version: 1,
    featured: true,
    type: "blog",
    postStatus: "posted",
    title: "MY CARELINQ CAREGIVER CHECKLIST",
    excerpt:
      "The purpose of this checklist is to provide caregivers an overview of topics that may help in the creation and management of a care management system that supports the wishes and instructions of the person receiving care.",
    featuredImage: MCLChecklist,
    author: {
      name: "MyCareLinq",
      bio: "",
      photo: DemoAuthorImageYoungWoman1,
    },
    url: "my-carelinq-caregiver-checklist",
    createdAt: "Oct 10, 2022",
    categories: [
      {
        name: "caregiver-checklist",
      },
    ],
    tags: [],
    content: `_MY CARELINQ CAREGIVER CHECKLIST_

###### **The purpose of this checklist is to provide caregivers an overview of topics that may help in the creation and management of a care management system that supports the wishes and instructions of the person receiving care.**
                
&nbsp;&nbsp;
    
By having conversations early, caregivers can be better prepared and learn how to be more effective in their role. Every caregiving situation is different and so we encourage you to adapt these topics to your situation as needed and update over time.
`,
  },
  {
    version: 1,
    featured: true,
    type: "blog",
    postStatus: "posted",
    title: "THE CAREGIVING JOURNEY: TRANSFORMING HOW WE CARE",
    excerpt:
      "In 2017, approximately 41 million family caregivers provided 34 billion hours of care that translated into $470 billion in unpaid contributions. By 2020, the number of caregivers had risen to 53 million Americans, nearly one in every five persons. This number is only expected to grow in the next few years as we face an aging society. Already, caregiving is reorganizing relations across American households and communities as these responsibilities extend into newer and younger generations. We find adults caring for children and parents, grandchildren caring for grandparents and vice versa, multiple people caring for an adult, and neighbors and friends caring for each other. The demands on caregivers are intense as they struggle to manage competing demands on their time all the while striving to care for their loved ones. As such, the needs of caregivers are complex, diverse, and dynamic.",
    featuredImage: MCLCaring,
    author: {
      name: "MyCareLinq",
      bio: "",
      photo: DemoAuthorImageProfWoman,
    },
    url: "the-caregiving-journey-transforming-how-we-are",
    createdAt: "Dec 15, 2021",
    categories: [
      {
        name: "caregiveing-journey",
      },
    ],
    tags: [],
    content: `_THE CAREGIVING JOURNEY: TRANSFORMING HOW WE CARE_

###### **TIn 2017, approximately 41 million family caregivers provided 34 billion hours of care that translated into $470 billion in unpaid contributions. By 2020, the number of caregivers had risen to 53 million Americans, nearly one in every five persons. This number is only expected to grow in the next few years as we face an aging society. Already, caregiving is reorganizing relations across American households and communities as these responsibilities extend into newer and younger generations. We find adults caring for children and parents, grandchildren caring for grandparents and vice versa, multiple people caring for an adult, and neighbors and friends caring for each other. The demands on caregivers are intense as they struggle to manage competing demands on their time all the while striving to care for their loved ones. As such, the needs of caregivers are complex, diverse, and dynamic.**
                
&nbsp;&nbsp;
    
For this reason, we need to innovarte new ways to care...for our loved ones, for those giving care, and for each other. This kind of care means giving caregivers the technical and technological tools they need to carry out their everyday responsibilities and feel confident in doing so. It means personalizing solutions that are tailored and adapted to each caregivers' circumstances, recognizing that every person's needs (including that of the care recipient) are unique. It means expanding caregivers' social networks and support systems so they do not feel alone in their journey but rather, belong to a wider community of peers to whom they can turn to for guidance. It means, most importantly, understanding cxaregiving as a deeply meaningful experience for caregivers and the ones for whom they are. The goal, therefore, is a seek out ways to enhance that caregiving journey.
`,
  },
];
export const DEMO_GET_ADJACENT_ARTICLES = async (index) => {
  return new Promise((resolve, reject) => {
    resolve({
      next:
        index + 1 > DEMO_ARTICLES.length - 1
          ? undefined
          : DEMO_ARTICLES[index + 1],
      previous: index - 1 < 0 ? undefined : DEMO_ARTICLES[index - 1],
    });
  });
};

export const DEMO_GET_ARTICLE_BY_CATEGORY = async (category) => {
  return new Promise((resolve, reject) => {
    resolve(
      DEMO_ARTICLES.filter((item) => {
        return (
          item.categories.length > 0 &&
          category != undefined &&
          item.categories[0]?.name === category
        );
      })
    );
  });
};

export const ARTICLE_IMAGE_PLACEHOLDER = PlaceHolderFeatureImage;
export const AUTHOR_IMAGE_PLACEHOLDER = PlaceHolderAuthorImage;
export const LIBRARY_IMAGES_FOLDER = {
  article: "articles",
  video: "videos",
};

export const MESSAGE_DELIVERY_STATUS = {
  0: "Failed",
  1: "Sent",
  2: "Read",
  undefined: "",
  null: "Error",
};

export const DEFAULT_MESSAGE_USER_ICON = RiUserFill;
export const DEFAULT_MESSAGE_GROUP_ICON = RiGroupFill;
export const MESSAGE_SUPPORT_ICON = RiCustomerService2Fill;
export const MESSAGE_CONCIERGE_ICON = FaConciergeBell;
export const MESSAGE_CHAT_TYPE = {
  GROUP: "group",
  DIRECT: "direct",
  MCL: { SUPPORT: "support", CONCIERGE: "concierge" },
};

export const CAREPLAN_PERMISSIONS = [
  "general",
  "personal",
  "health",
  "social",
  "legal",
  "finances",
  "endOfLife",
  "afterLife",
];
export const PERMISSION_ICON_MAPPING = {
  general: <FaHome className="text-black/70" />,
  personal: <FaUserAlt className="text-black/70" />,
  health: <FaBriefcaseMedical className="text-black/70" />,
  social: <FaUsers className="text-black/70" />,
  legal: <GoLaw className="text-black/70" />,
  finances: <RiCoinsFill className="text-black/70" />,
  endOfLife: <FaHandshake className="text-black/70" />,
  afterLife: <FaChurch className="text-black/70" />,
};

export const PERMISSION_ICON_COLOR_MAPPING = {
  general: "bg-permission-1/60",
  personal: "bg-permission-2/60",
  health: "bg-permission-3/60",
  social: "bg-permission-4/60",
  legal: "bg-permission-5/60",
  finances: "bg-permission-6/60",
  endOfLife: "bg-permission-7/60",
  afterLife: "bg-permission-8/60",
};

export const DEFAULT_CAREPLAN_PERMISSIONS = {
  general: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  personal: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  health: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  social: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  legal: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  finances: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  endOfLife: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
  afterLife: {
    read: false,
    write: false,
    delete: false,
    docUpload: false,
    docDownload: false,
    docDelete: false,
    docReplace: false,
  },
};

export const CAREPLAN_PERMISSIONS_ALL_ON = {
  general: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  personal: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  health: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  social: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  legal: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  finances: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  endOfLife: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
  afterLife: {
    read: true,
    write: true,
    delete: true,
    docUpload: true,
    docDownload: true,
    docDelete: true,
    docReplace: true,
  },
};

export const AUTH_KEY = "user";
export const AUTH_KEY_TTL = 60*60*1000; // set to expire 1 hour 