import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import ReactGA from "react-ga4";
import useMclAuth from "../../hooks/useMclAuth.js";

import { Navbar } from "../../components/Navbar.js";
import { PurpleDivider } from "../../components/Various.js";
import { Col, Row, Button, Input } from "antd";
// import ServiceProductv2 from "./Productv2.js";
import ServiceProductv3 from "./Productv3.js";
import PrepaidProduct from "./Prepaid.js";
// import CustomProduct from "./Custom.js";
import CustomProductv2 from "./Customv2.js";
import { Url } from "../../constants/Global.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import "./Pricing.css";
import Footer from "../../components/Footer.js";
import { mclOpen } from "../../api/Mcl.js";
import { Container } from "postcss";

import useAuth from "../../hooks/useAuth.js";

function Pricingv3() {
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState("FREE");
  const [expiration, setExpiration] = useState("");
  const [redeemButtonLoading, setRedeemButtonLoading] = useState(false);
  const [giftCode, setGiftCode] = useState("");
  const [giftCodeCheck, setGiftCodeCheck] = useState(false);

  const mclAuth = useMclAuth();
  const { auth } = useAuth();

  // console.log('!@$*&*^^% props auth:', auth);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute reCAPTCHA not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    mclAuth
      .get(Url.PAYMENT.concat("/currentPlan"))
      .then((res) => {
        if (res.data) {
          setCurrentPlan(res.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [mclAuth, currentPlan /*expiration*/]);

  const handleRedeem = () => {
    ReactGA.event({
      category: "pricing",
      action: "click_button",
      label: "redeem_gift_code",
    });
    setRedeemButtonLoading(true);
    if (!giftCodeCheck) {
      alert("Please enter gift code first.");
      setRedeemButtonLoading(false);
    } else {
      const recaptchaFirst = async () => {
        let recaptchaToken = await handleReCaptchaVerify();

        if (recaptchaToken) {
          mclOpen
            .get(Url.GIFTING.concat("/redeemRecaptcha"), {
              params: {
                token: recaptchaToken,
              },
            })
            .then((res) => {
              if (res.data === "ok") {
                mclAuth
                  .get(Url.GIFTING.concat("/redeemGift/" + giftCode))
                  .then((res1) => {
                    alert(res1.data);
                    setRedeemButtonLoading(false);
                  })
                  .catch((error) => {
                    console.log(error.message);
                    setRedeemButtonLoading(false);
                  });
              } else {
                alert("Recaptcha failed");
                setRedeemButtonLoading(false);
              }
            })
            .catch((error) => {
              console.log(error.message);
              setRedeemButtonLoading(false);
            });
        } else {
          setRedeemButtonLoading(false);
        }
      };

      recaptchaFirst();
    }
  };

  return (
    <main>
      <Navbar signin={true} navigate={navigate} />
      {/* <section className="bg-[url('assets/images/about-us/header-bg.png')] min-h-[2rem] max-h-[12rem] bg-center bg-no-repeat bg-cover overflow-hidden flex"> */}
      <section className="min-h-[2rem] max-h-[12rem] bg-center bg-no-repeat bg-cover overflow-hidden flex">
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-nowrap">
          <Row>
            <Col xs={24} className="text-primary-purple text-center">
              {/* <h1 className="pt-6 xl:text-[4rem] lg:text-[3rem] md:text-[2rem] sm:text-[1.5rem] xs:text-[1.25rem]">
                You Choose the Level of Support
              </h1> */}
              {/* <h2 className="xl:text-[3rem] lg:text-[2.25rem] md:text-[2rem] sm:text-[1.25rem] xs:text-[1rem]">
                You Choose the Level of Support
              </h2> */}
            </Col>
          </Row>
        </div>
      </section>
      {/* <PurpleDivider /> */}
      {/* <div className="xs:container lg:mx-auto px-4 flex flex-col justify-center pt-10"> */}
      <div className="xs:container lg:mx-auto px-4 flex flex-col justify-center">
        {/* <Row className="mt-8"> */}
        <Row>
          <Col className="mx-auto">
            {/*<p className="mb-4 p-0 text-center text-orange-500 text-2xl">
                          Current plan of your circle: <strong>{currentPlan}</strong> expiration
                        </p>*/}
            {/* <p className="mb-4 p-0 text-center text-orange-500 text-2xl">
              Redeem your gift code:{" "}
              <Input
                placeholder="Paste gift code here then press 'Redeem' button."
                size="large"
                style={{
                  width: 420,
                }}
                onChange={(e) => {
                  setGiftCode(e.target.value);
                  setGiftCodeCheck(e.target.value ? true : false);
                }}
              ></Input>
              <Button
                className={
                  "mx-2 my-1 border-0 rounded-lg bg-[#44cff2] text-white text-xl h-10"
                }
                onClick={handleRedeem}
                loading={redeemButtonLoading}
              >
                Redeem
              </Button>
            </p> */}
            {/*<p className="m-0 p-0 text-center text-2xl">
                            You choose the level of care you need.
                        </p>*/}
          </Col>
        </Row>
        {/* <Row className="mb-10 my-6">
          <Col xs={24}>
            <p className="m-0 px-10 text-center xxl:text-[1.75rem] xl:text-[1.5rem] lg:text-[1.5rem] md:text-[1.25rem] sm:text-[1.25rem] leading-normal">
              Whether you want to get started on your own OR need assistance
              today, we will work with you to solve your challenges through
              holistic care and support coordination.
            </p>
          </Col>
        </Row> */}
      </div>
      <ServiceProductv3
        inSub={currentPlan}
        setCurrentPlan={setCurrentPlan}
        setExpiration={setExpiration}
        b2b={auth?.b2b}
      />
      {/* <PurpleDivider /> */}
      <div className="xs:container lg:mx-auto px-4 flex flex-col justify-center mt-10">
        <Row className="mt-8">
          {/* <Col className="mx-auto">
            <p className="m-0 p-0 text-center xxl:text-[3.5rem] xl:text-[3.25rem] lg:text-[3rem] md:text-[2.5rem] sm:text-[2rem] xs:text-[2.5rem]">
              Not what you're looking for?
            </p>
          </Col> */}
        </Row>
        <Row className="mx-5 my-6 mt-10 mb-10">
          <Col xs={24}>
            {/* <p className="m-0 md:px-24 text-center xxl:text-[2.25rem] xl:text-[2.25rem] lg:text-[2.25rem] md:text-[2rem] sm:text-[2rem] leading-normal"> */}
            <p className="m-0 md:px-24 text-center xxl:text-[1.5rem] xl:text-[1.5rem] lg:text-[1.5rem] md:text-[1.25rem] sm:text-[1.25rem] leading-normal">
              We have <strong>customizable packages</strong> to meet your needs.
            </p>
          </Col>
        </Row>
      </div>
      {/* <PrepaidProduct
        inSub={currentPlan}
        setCurrentPlan={setCurrentPlan}
        setExpiration={setExpiration}
        b2b={auth?.b2b}
      /> */}
      <div className="mb-20">
        <CustomProductv2
          inSub={currentPlan}
          setCurrentPlan={setCurrentPlan}
          setExpiration={setExpiration}
          b2b={auth?.b2b}
        />
      </div>
      {/* <div className="footer-purple-box">
        <div className="w-full lg:container lg:mx-auto px-3" id="contact-form">
          <Row>
            <Col xs={24}>
              <p className="footer-cta-text">
                Get Started Today to Get Support, Answers, and Assistance.{" "}
                <br />
                We are here to help.
              </p>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <PurpleDivider /> */}
      <Footer style={{ textAlign: "center" }}></Footer>
    </main>
  );
}

export default Pricingv3;
