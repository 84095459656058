import React, { useState } from "react";
import { useLocation } from "react-router-dom";

//import { useNavigate } from "react-router";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { Dropdown, Menu, Space } from "antd";

import "./Navbar.css";
import "../assets/styles/Landing.css";
import logoB2b from "../assets/images/b2b/b2b-hands.png";
import logo from "../assets/images/logos/mcl-logo-color.svg";
import logoText from "../assets/images/logos/mcl-logo-white-horizontal-text-only-new.png";

import GetStartedButton from "./GetStartedButton";

// meta pixel integration
import MetaPixel from "../api/metaPixel";

import B2bLogo from "./B2bLogo";


// const menu = (
//     <Menu
//         items={[
//             {
//                 label: <a href="#platform-overview" className="nav-link">Platform Overview</a>,
//                 key: '0',
//             },
//             {
//                 label: <a href="#platform-built4u" className="nav-link">Built for You</a>,
//                 key: '1',
//             },
//             {
//                 label: <a href="#platform-whyMCL" className="nav-link">Why My CareLinq?</a>,
//                 key: '2',
//             },
//         ]}
//     />
// );

const NavLink = ({ to, children }) => {
  return (
    <Link
      className="nav-link text-base"
      to={to}
      onClick={() => {
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: "auto",
        });
      }}
    >
      {children}
    </Link>
  );
};

export const Navbar = ({ signin = false, navigate = null, b2b = null }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const aboutUsMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: <NavLink to="/about-us">About Us</NavLink>,
        },
        {
          key: "2",
          label: <NavLink to="/team">Our Team</NavLink>,
        },
      ]}
    />
  );

  const productMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: <NavLink to="/product">Enterprise</NavLink>,
        },
        {
          key: "2",
          label: <NavLink to="/product-consumer">Consumer</NavLink>,
        },
      ]}
    />
  );

  const libraryMenu = (
    <Menu
      items={[
        {
          key: "article",
          label: <NavLink to="/library/article">Articles</NavLink>,
        },
        {
          key: "video",
          label: <NavLink to="/library/video">Videos</NavLink>,
        },
      ]}
    />
  );

  const locationPartnerForm = useLocation().pathname + "?modal=partner";

  return (
    <nav className="navbar nav-color navbar-expand-lg sticky-top">
      <MetaPixel />
      <div className="w-full px-1 flex flex-wrap items-center justify-between">
        <span className="navbar-brand">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({
                left: 0,
                top: 0,
                behavior: "auto",
              });
            }}
          >
            <img
              className="nav-logo inline-block"
              src={logo}
              height="25"
              weign="25"
              alt="My CareLinq Icon"
            />
            <img
              className="nav-logo-text inline-block"
              src={logoText}
              height="20"
              weign="20"
              alt="My CareLinq"
            />
            {/* { b2b ?
            <img
              className="nav-logo-inline-block"
              src={logoB2b}
              height="20"
              weign="20"
              alt="B2B icon"
            /> : null 
            } */}
            <B2bLogo b2b={b2b} />
          </Link>
        </span>
        <div className="navbar-collapse collapse">
          <div className="navbar-nav mr-auto">
            <NavLink to="/">Home</NavLink>
            {/* <a href="#testimonials" className="nav-link text-base">Testimonials</a>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a className="nav-link dropdown-toggle text-base" onClick={e => e.preventDefault()}>
                                <Space>
                                    Platform
                                </Space>
                            </a>
                        </Dropdown> */}
            {/* <NavLink to="/about-us">About Us</NavLink> */}
            {/* <NavLink to="/product">Product</NavLink> */}
            <Dropdown overlay={productMenu}>
              <a
                className="nav-link dropdown-toggle text-base"
                onClick={(e) => e.preventDefault()}
              >
                <Space>Product</Space>
              </a>
            </Dropdown> 
            <NavLink to="/pricing">Store</NavLink>
            {/* <NavLink to="/?modal=partner">Contact Us</NavLink> */}
            <NavLink to={locationPartnerForm}>Contact Us</NavLink>
            {/* <NavLink to="/team">Team</NavLink> */}
            <NavLink to="/about-us">About Us</NavLink>
            {/* <Dropdown overlay={aboutUsMenu}>
              <a
                className="nav-link dropdown-toggle text-base"
                onClick={(e) => e.preventDefault()}
              >
                <Space>About Us</Space>
              </a>
            </Dropdown>  */}
            {/* <NavLink to="/how-we-can-help">We Can Help</NavLink> */}
            {/* <NavLink to="/?modal=schedule">Ask Min</NavLink> */}
            {/* <Dropdown overlay={libraryMenu}>
              <a
                className="nav-link dropdown-toggle text-base"
                onClick={(e) => e.preventDefault()}
              >
                <Space>Library</Space>
              </a>
            </Dropdown> */}
            {/* <NavLink to="/pricing">Pricing</NavLink> */}
            {/* <NavLink to="/survey/referral">Referrals</NavLink> */}
            {/* <NavLink to="/enterprise">Enterprise</NavLink> */}
            {/* <NavLink to="/team">Team</NavLink> */}
          </div>
          {/* Nav Button*/}
          <div>
          {/* <GetStartedButton
              className="mr-2"
              onClick={() => {
                navigate("/survey");
              }}
              style={{ display: signin ? "inline-block" : "none" }}
            >
              Contact Us
            </GetStartedButton> */}
            <GetStartedButton
              className="mr-2"
              onClick={() => {
                navigate("/survey");
              }}
              style={{ display: signin ? "inline-block" : "none" }}
            >
              Create Account
            </GetStartedButton>
            <GetStartedButton
              onClick={() => {
                navigate("/site/login");
              }}
              style={{ display: signin ? "inline-block" : "none" }}
            >
              Login
            </GetStartedButton>
          </div>
        </div>
        {/* Mobile nav bar toggler */}
        <button className="nav-toggle-button" onClick={toggleCollapsed}>
          {<MenuOutlined />}
        </button>
        {/* Mobile nav bar */}
        <CSSTransition
          in={collapsed}
          timeout={300}
          classNames="nav-expand"
          unmountOnExit
        >
          <div className="mobile-nav-bar" key="mobile-nav-bar">
            <NavLink to="/">Home</NavLink>
            {/* <NavLink to="/product">Product</NavLink> */}
            <Dropdown overlay={productMenu}>
              <a
                className="nav-link dropdown-toggle text-base"
                onClick={(e) => e.preventDefault()}
              >
                <Space>Product</Space>
              </a>
            </Dropdown> 
            <NavLink to="/?modal=partner">Contact Us</NavLink>
            <NavLink to="/about-us">About Us</NavLink>
            <NavLink to="/team">Team</NavLink>
            {/* <NavLink to="/?modal=schedule">Ask Min</NavLink> */}
            {/* <NavLink to="/how-we-can-help">We Can Help</NavLink>
            <NavLink to="/library/article">Articles</NavLink>
            <NavLink to="/library/video">Videos</NavLink> */}
            {/* <NavLink to="/survey/referral">Referrals</NavLink> */}
            {/* <NavLink to="/enterprise">Enterprise</NavLink> */}
            {/* Nav Button*/}
            <GetStartedButton
              className="my-2"
              onClick={() => {
                navigate("/survey");
              }}
              style={{ display: signin ? "inline-block" : "none" }}
            >
              Create Account
            </GetStartedButton>
            <GetStartedButton
              className="my-2"
              onClick={() => {
                navigate("/site/login");
              }}
              style={{ display: signin ? "inline-block" : "none" }}
            >
              SIGN IN
            </GetStartedButton>
          </div>
        </CSSTransition>
      </div>
    </nav>
  );
};
