import React, { useRef, useEffect, useState } from 'react'

import _ from "lodash";
import { Modal, Input, Form, Spin, Button, Row, Col, Select, Card, Descriptions, Collapse, List,  DatePicker, Tag, message, Table, Space } from 'antd';
import { FileSyncOutlined } from '@ant-design/icons';

import { mclOpen } from "../api/Mcl"
import { Url } from '../constants/Global';
import { openNotification } from "./Various";


const { Option } = Select;


const ViewTextModal = ({ modalTitle = "View", modalShow, handleOk, handleCancel, value }) => {
  return (
      <Modal
          title={modalTitle}
          open={modalShow}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={"Close"}
          width={1000}
      >
          <Input.TextArea
              showCount
              // maxLength={3000}
              rows={30}
              style={{ height: "100%" }}
              // onChange={onChange}
              value={value}
              readonly={true}
              spellcheck={false}
              placeholder="Type here."
          />
      </Modal>
  )
}

const GeneratePlanModal = (
  { 
    modalShow, 
    modalTitle, 
    handleOk, 
    handleCancel = () => {},
    isSubmitting = false,
    submitForm = (values) => {
      console.log(values);
    },
    form = undefined,
    llms = [],
    prompts = [],
    audioName = "",
    transcriptName = "",
  }) => {

  console.log('audioName', audioName);
  return (
      <Modal
          title={"Generate Care Plan"}
          open={modalShow}
          // onOk={handleOk}
          onCancel={handleCancel}
          // okText={"Close"}
          width={1000}
          footer={[]}
          // footer={[
          //   <Button
          //     key="back"
          //     shape="round"
          //     onClick={handleCancel}
          //     loading={isSubmitting}
          //     disabled={isSubmitting}
          //   >
          //     Cancel
          //   </Button>,
          //   <Button
          //     key="submit"
          //     type="primary"
          //     shape="round"
          //     icon={<FileSyncOutlined />}
          //     onClick={form && form.submit}
          //     loading={isSubmitting}
          //     disabled={isSubmitting}
          //   >
          //     Generate Care Plan
          //   </Button>,
          // ]}
    
      >
        <GeneratePlanForm
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          form={form}
          llms={llms}
          prompts={prompts}
          audioName={audioName}
          transcriptName={transcriptName}
          handleCancel={handleCancel}
        />
      </Modal>
  )
}

const GeneratePlanForm = ({
  isSubmitting = false,
  handleCancel = () =>{},
  submitForm = () => {},
  form = undefined,
  audioName = "",
  transcriptName = "",
  llms = [
    // {name: 'openai', desc: 'Open AI', models: ["gpt-4o", "gpt4", "gpt4-turbo", "gpt-4-turbo-preview", "gpt-4-32k", "gpt-4-32k-0314", "gpt-4-32k-v0314", "gpt-4-vision-preview"]},
    // {name: 'mistral', desc: 'Mistral', models: ["mistral-large-latest", "open-mistral-nemo"]},
    // {name: 'anthropic', desc: 'Anthropic', models: ["claude-3-opus-20240229", "claude-3-sonnet-20240229","claude-3-haiku-20240307"]},
    // {name: 'ollama', desc: 'OLlama', models: ["llama3", "gemma2", "mistral-nemo", "mistral-large", "qwen2", "phi3"]},
  ],
  prompts = [
    // {name: 'custom', value: 'You are a caregiver .....'}
  ]
}) => {

  const [currentLlm, setCurrentLlm] = useState(llms[0].name);
  const [currentModel, setCurrentModel] = useState(llms[0].models[0]);
  const [currentModels, setCurrentModels] = useState(llms[0].models);
  const [currentPromptTemplate, setCurrentPromptTemplate] = useState(prompts[0].value);
  const [currentPrompt, setCurrentPrompt] = useState(currentPromptTemplate);
  const [currentCarePlan, setCurrentCarePlan] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  
  const generatePlan = async () => {
    try {
      // post to generate care plan
      setIsGenerating(true);
      const response = await mclOpen.post(
        Url.PLAN.concat("/generate"),
        JSON.stringify({
          mp3: audioName,
          llm: currentLlm,
          model: currentModel,
          prompt: currentPrompt,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      setCurrentCarePlan(response?.data?.result?.care_plan);
      setIsGenerating(false);
    } catch (error) {
      // console.log('post plan error!', error?.response);
      ;
      setIsGenerating(false);
      openNotification({
        message: "Sorry",
        status: error.status ? error.status : "500",
        title: error.response.data
    });
    // setSubmitting(false);

    }
        // const accessToken = response?.data?.accessToken;
        // const roles = response?.data?.roles;
        // const care = response?.data?.care;
        // const id = response?.data?.id;
  };

  const onFinish = (values) => {
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onLlmChange = (nameLlm) => {
    console.log("Change llm:", nameLlm);
    setCurrentLlm(nameLlm);
    const models = getModels(nameLlm);
    setCurrentModel(models[0]);
    setCurrentModels(models);
  };

  const onModelChange = (nameModel) => {
    setCurrentModel(nameModel);
  };

  const onPromptTemplateChange = (promptTemplate) => {
    setCurrentPromptTemplate(promptTemplate);
    setCurrentPrompt(promptTemplate);
  };

  const onPromptChange = (e) => {
    setCurrentPrompt(e.target.value);
  };

  const getModels = (llmName) => {
    const target = llms.find(llm => llm.name === llmName);
    return target ? target.models : [];
  };

  var modelOptions = (
    <Select value={currentModels[0]} >
    {currentModels.map((model, index) => (
    <Option value={model} key={index}>
      {model}
    </Option>
    ))}
    </Select>)
    ;


  return (
    <Spin spinning={isSubmitting}>
      {/* <Form
        className="generateplanForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{["prompt"]: currentPromptTemplate}}
      > */}
        {/* <h2 className="text-h2 text-primary-purple">
          Care Plan for {audioName}
        </h2> */}
        <Row>
          <Col md={8}><h4>Recording:</h4></Col>
          <Col md={4}><h4>LLM:</h4></Col>
          <Col md={8}><h4>Model:</h4></Col>
          <Col md={4}><h4>Prompt Template:</h4></Col>
        </Row>
        <Row>
          <Col md={8}>
            <h3>{audioName}</h3>
          </Col>
          <Col md={4}>
            {/* <Form.Item
              name="llm"
              label="LLM Provider"
              rules={[
                {
                  required: true,
                  message: "Please select the llm provider!",
                },
              ]}
            > */}
              {/* <Select defaultValue={llms[0].name}   */}
              <Select defaultValue={currentLlm}  
                onChange={onLlmChange}
              >
                {llms.map((llm, index) => (
                <Option value={llm.name} key={index}>
                  {llm.desc}
                </Option>
                ))}
              </Select>
            {/* </Form.Item> */}
          </Col>
          <Col md={8}> 
            {/* <Form.Item
              name="model"
              label="LLM Models"
              initialValue={currentModel}
              rules={[
                {
                  required: true,
                  message: "Please select the model",
                },
              ]}
              value={currentModel}
            > */}
              {/* defaultValue={currentModels[0]} value={currentModels[0]} */}
              {/* {currentModels ? */}
              <Select 
                // defaultValue={currentModels[0]} 
                value={currentModel}
                onChange={onModelChange}
              >
                {currentModels.map((model, index) => (
                <Option value={model} key={index}>
                  {model}
                </Option>
                ))}
              </Select> 
              {/* : null } */}
                {/* {modelOptions} */}
                {/* </Form.Item> */}
          </Col>
          <Col md={4}>
            {/* <Form.Item
              name="template"
              label="Prompt Template"
              // initialValues={prompts[0].value}
              rules={[
                {
                  required: true,
                  message: "Please select the desired template for care plan prompt",
                },
              ]}
            > */}
              <Select 
                // defaultValue={prompts[0].value}  
                defaultValue={currentPromptTemplate}  
                onChange={onPromptTemplateChange}
              >
                {prompts.map((prompt, index) => (
                <Option value={prompt.value} key={index}>
                  {prompt.name}
                </Option>
                ))}
              </Select>
            {/* </Form.Item> */}
          </Col>
        </Row>
        {/* <Row>
          <Col md={4}><h2>{currentLlm}</h2></Col>
          <Col md={4}><h3>{currentModel}</h3></Col>
          <Col md={16}><h4>{JSON.stringify(currentModels)}</h4></Col>
        </Row> */}
        {/* <Row>
          {currentPromptTemplate}
        </Row> */}
        <Row>
          <Col md={24}><h4>Prompt:</h4></Col>
        </Row>
        <Row>
          <Col md={24}>
            {/* <Form.Item
              name="prompt"
              label="Prompt"
              // initialValues={currentPromptTemplate}
              rules={[
                {
                  required: true,
                  message: "Please input the desired care plan prompt",
                },
              ]}
            > */}
              <Input.TextArea
                  showCount
                  maxLength={3000}
                  rows={10}
                  readOnly={false}
                  // style={{ height: "100%" }}
                  defaultValue={currentPromptTemplate}
                  onChange={onPromptChange}
                  value={currentPrompt}
                  placeholder="Type here."
              />
            {/* </Form.Item> */}
          </Col>
        </Row>        
        <Row className='mt-8'>
          <Col md={15}/>
          <Col md={2}>
          <Button
              key="back"
              shape="round"
              onClick={handleCancel}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Cancel
            </Button>,
          </Col>
          <Col md={1}/>
          <Col md={6}>
            <Button
              key="submit"
              type="primary"
              shape="round"
              icon={<FileSyncOutlined />}
              // onClick={form && form.submit}
              onClick={generatePlan}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Generate Care Plan
            </Button>
          </Col>
        </Row>      
        <Row>
          <Col md={24}><h4>Care Plan:</h4></Col>
        </Row>
        <Row>
          <Col md={24}>
            {/* <Form.Item
              name="prompt"
              label="Prompt"
              // initialValues={currentPromptTemplate}
              rules={[
                {
                  required: true,
                  message: "Please input the desired care plan prompt",
                },
              ]}
            > */}
               <Spin spinning={isGenerating}>
              <Input.TextArea
                  showCount
                  maxLength={3000}
                  rows={30}
                  readOnly={true}
                  // style={{ height: "100%" }}
                  // onChange={onPromptChange}
                  value={currentCarePlan}
                  // placeholder="Type here."
              />
              </Spin>
            {/* </Form.Item> */}
          </Col>
        </Row>  
      {/* </Form> */}
    </Spin>
  )
}

export { ViewTextModal, GeneratePlanModal, GeneratePlanForm };